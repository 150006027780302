'use client';

import { useState, useEffect } from 'react';
import { Page } from '@/payload-types';
import { Faqs } from '@/blocks/Faqs';
import { clsx } from 'clsx';
import { IconsAccordions } from '@/components/IconsAccordions';

type IconsTabsProps = {
  iconsTabsFields: NonNullable<Page['accordions']>['iconsTabsFields'];
};

export const IconsTabs = ({ iconsTabsFields }: IconsTabsProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const item = iconsTabsFields?.item;

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      const currentHashTab =
        item?.findIndex(({ accordions }) => accordions?.some(({ anchor }) => anchor === hash)) ?? 0;
      setCurrentTab(currentHashTab);
    };

    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, [item]);

  if (!iconsTabsFields) {
    return null;
  }

  if (!item?.length) {
    return null;
  }

  return (
    <>
      {item.map(({ faqsFields }, index) => {
        if (!faqsFields) {
          return null;
        }

        return <Faqs faqsFields={faqsFields} className={clsx({ hide: index !== currentTab })} />;
      })}
      <IconsAccordions iconsTabsFields={iconsTabsFields} currentTab={currentTab} setCurrentTab={setCurrentTab} />
    </>
  );
};

'use client';

import { useEffect, useRef } from 'react';
import classes from './index.module.scss';
import { ExtractBlockField } from '@/utils/types';
import useOnScreen from '@/utils/useOnScreen';
import { CMSLink } from '@/components/CMSLink';
import { Video } from '@/components/Video';
import { useStickyButtonContext } from '@/providers/StickyButton';
import { clsx } from 'clsx';
import { RichText } from '@/components/RichText';

type VideoCtaProps = {
  videoCtaFields: ExtractBlockField<'VideoCta', 'videoCtaFields'>;
};

export const VideoCta = (props: VideoCtaProps) => {
  const {
    videoCtaFields: { video, image_position, heading, headingRichText, description, descriptionRichText, link }
  } = props;

  const sectionRef = useRef(null);
  const isOnScreen = useOnScreen(sectionRef);
  const { setShowStickyButton } = useStickyButtonContext();

  useEffect(() => {
    setShowStickyButton(!isOnScreen);
  }, [isOnScreen]);

  if (!video) {
    return null;
  }

  return (
    <section>
      <div className="container" ref={sectionRef}>
        <div className={classes.section}>
          <div>
            <div className={clsx(classes.row, { [classes.left]: image_position === 'left' })}>
              <div className={clsx(classes.col, classes.big)}>
                {heading && !headingRichText && (
                  <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: heading }} />
                )}
                {headingRichText && (
                  <h1 className={classes.title}>
                    <RichText content={headingRichText} />
                  </h1>
                )}

                {description && !descriptionRichText && (
                  <div className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {descriptionRichText && <RichText content={descriptionRichText} className={classes.description} />}

                {link && (
                  <div className={classes.buttonWrapper}>
                    <CMSLink {...link} className={classes.button} />
                  </div>
                )}
              </div>

              <div className={clsx(classes.col, classes.small)}>
                <Video {...video} />

                {link && (
                  <div>
                    <CMSLink {...link} className={clsx(classes.button, classes.mobile)} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

'use client';

import { register } from 'swiper/element/bundle';

import type { ExtractBlockField, RichTextType } from '@/utils/types';
import { Media } from '@/components/Media';
import { CheckmarkIcon } from '@/icons/Checkmark';
import { CrossIcon } from '@/icons/Cross';
import classes from './index.module.scss';
import { clsx } from 'clsx';
import { RichText } from '@/components/RichText';

type OfferTableProps = {
  offerTableContent: ExtractBlockField<'offerTable', 'offerTableContent'>;
};

register();

const iconComponents = {
  checkmark: <CheckmarkIcon width={24} height={24} />,
  cross: <CrossIcon width={24} height={24} />,
  empty: null
};

export const OfferTable = ({
  offerTableContent: {
    oneTimeTitleIcon,
    oneTimeTitle,
    oneTimeTitleRichText,
    extensionTitleIcon,
    extensionTitle,
    extensionTitleRichText,
    printTitleIcon,
    printTitle,
    printTitleRichText,
    mobileSectionTitle,
    rows
  }
}: OfferTableProps) => {
  if (!rows?.length) {
    return null;
  }

  const colHeaders = [
    { title: extensionTitle, icon: oneTimeTitleIcon, titleRichText: extensionTitleRichText },
    { title: oneTimeTitle, icon: printTitleIcon, titleRichText: oneTimeTitleRichText },
    { title: printTitle, icon: extensionTitleIcon, titleRichText: printTitleRichText }
  ];

  const mobileSlides = Array.from({ length: 3 }).map((_, index) => {
    const { title, icon, titleRichText } = colHeaders[index];
    const fields = rows.flatMap(row => row.columns && row.columns[index]);

    return {
      titleRichText,
      title,
      icon,
      fields
    };
  });

  return (
    <section className={classes.section}>
      <div className={clsx('container', classes.container)}>
        <div className={classes.bodyDesktop}>
          <div className={classes.table}>
            {colHeaders.map(({ icon, title, titleRichText }) => (
              <div className={clsx(classes.col, classes.colFirstLine)}>
                {icon && <Media source={icon} width={27} height={30} />}

                {title && !titleRichText && (
                  <p dangerouslySetInnerHTML={{ __html: title }} className={classes.tableTitle} />
                )}
                {titleRichText && <RichText content={titleRichText as RichTextType} className={classes.tableTitle} />}
              </div>
            ))}

            {rows.flatMap(({ columns }) =>
              columns?.map(({ text, title, titleRichText, textRichText, iconType }) =>
                text || title ? (
                  <div className={classes.col}>
                    {title && !titleRichText && (
                      <p className={classes.colTitle} dangerouslySetInnerHTML={{ __html: title }} />
                    )}
                    {titleRichText && <RichText content={titleRichText} className={classes.colTitle} />}

                    <div className={classes.colContent}>
                      {iconType && (
                        <div className={clsx(classes.colContentIconWrapper, classes[iconType])}>
                          {iconComponents[iconType]}
                        </div>
                      )}
                      {textRichText && <RichText content={textRichText} className={classes.colText} />}
                      {text && !textRichText && (
                        <p className={classes.colText} dangerouslySetInnerHTML={{ __html: text }} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={clsx(classes.col, classes.emptyCol)}>
                    <div className={clsx(classes.colContentIconWrapper, classes.cross)}>{iconComponents.cross}</div>
                  </div>
                )
              )
            )}
          </div>
        </div>

        <div className={classes.bodyMobile}>
          {mobileSectionTitle && <h2 className={classes.title}>{mobileSectionTitle}</h2>}

          <div className={classes.sliderWrapper}>
            <swiper-container
              slides-per-view={1}
              space-between={16}
              auto-height={true}
              grab-cursor={true}
              loop={true}
              pagination={true}
            >
              {mobileSlides.map(({ title, titleRichText, icon, fields }, index) => (
                <swiper-slide key={index}>
                  <div className={classes.slide}>
                    {title && (
                      <div className={classes.slideHeader}>
                        {icon && <Media source={icon} className={classes.slideHeaderIcon} width={35} height={38} />}

                        {titleRichText && (
                          <RichText content={titleRichText as RichTextType} className={classes.tableTitle} />
                        )}
                        {title && !titleRichText && (
                          <p className={classes.tableTitle} dangerouslySetInnerHTML={{ __html: title }} />
                        )}
                      </div>
                    )}

                    {fields && (
                      <ul className={classes.slideList}>
                        {fields.map((field, index) => {
                          if (!field) return null;

                          const { title, titleRichText, textMobile, textMobileRichText, iconType } = field;

                          return (
                            <li className={classes.col} key={index}>
                              {title && !titleRichText && (
                                <p className={classes.colTitle} dangerouslySetInnerHTML={{ __html: title }} />
                              )}
                              {titleRichText && <RichText content={titleRichText} className={classes.colTitle} />}

                              {(textMobile || textMobileRichText) && (
                                <div className={classes.colContent}>
                                  {iconType && (
                                    <div className={clsx(classes.colContentIconWrapper, classes[iconType])}>
                                      {iconComponents[iconType]}
                                    </div>
                                  )}
                                  {textMobile && !textMobileRichText && (
                                    <p
                                      className={clsx(classes.colText, { [classes.cross]: iconType === 'cross' })}
                                      dangerouslySetInnerHTML={{ __html: textMobile }}
                                    />
                                  )}
                                  {textMobileRichText && (
                                    <RichText
                                      content={textMobileRichText}
                                      className={clsx(classes.colText, { [classes.cross]: iconType === 'cross' })}
                                    />
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </swiper-slide>
              ))}
            </swiper-container>
          </div>
        </div>
      </div>
    </section>
  );
};

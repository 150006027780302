'use client';

import { RefObject, useEffect } from 'react';
import { useStickyButtonContext } from '@/providers/StickyButton';

type StickyCtaControllerProps = {
  element?: RefObject<Element | null>; // show sticky CTA if element is not visible if not show after 25% of page was scrolled
};

export const StickyCtaController = ({ element }: StickyCtaControllerProps) => {
  const { setShowStickyButton } = useStickyButtonContext();

  useEffect(() => {
    if (!setShowStickyButton) {
      return;
    }

    const scrollHandler = () => {
      if (!setShowStickyButton) return;

      if (element?.current) {
        const { top, height } = element.current.getBoundingClientRect() || { top: 0, height: 0 };
        setShowStickyButton(top + height <= 0);
      } else {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercentage = (scrollTop / scrollHeight) * 100;

        setShowStickyButton(scrollPercentage > 25);
      }
    };

    document.addEventListener('scroll', scrollHandler);
    scrollHandler();

    return () => document.removeEventListener('scroll', scrollHandler);
  }, [element, setShowStickyButton]);

  return null;
};

'use client';
import '@atomicleads/ext-satellite-form/index.css';
import classes from './index.module.scss';
import { ContactForm, Login, ForgotPassword, Registration, ForgotUsername } from '@atomicleads/ext-satellite-form';
import { ExtractBlockField } from '@/utils/types';

type CommonComponentProps = {
  mediaBlockFields: ExtractBlockField<'commonComponent', 'mediaBlockFields'>;
};

export const CommonComponent = (props: CommonComponentProps) => {
  const component = props?.mediaBlockFields?.component;
  let Component = <></>;

  switch (component) {
    case 'login':
      Component = <Login productFocus={'extension'} />;
      break;
    case 'registration':
      Component = <Registration productFocus={'extension'} disablePasswordConfirm />;
      break;
    case 'contactUs':
      Component = <ContactForm color={'#1D77B8'} recaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string} />;
      break;
    case 'passwordRecovery':
      Component = <ForgotPassword />;
      break;
    case 'usernameRecovery':
      Component = <ForgotUsername />;
      break;
    default:
      return null;
  }

  return (
    <section className={classes.section}>
      <div className={`container ${classes.container}`}>{Component}</div>
    </section>
  );
};

import { CSSProperties } from 'react';
import classes from './index.module.scss';
import { Button } from '@/components/Button';
import { ExtractBlockField } from '@/utils/types';
import { RichText } from '@/components/RichText';

type SimpleCtaProps = {
  simpleCTA: ExtractBlockField<'simpleCTA', 'simpleCTA'>;
};

export const SimpleCta = (props: SimpleCtaProps) => {
  const { heading, headingRichText, link, background, text, buttonBackground, buttonTextColor } = props.simpleCTA;

  if (!link) {
    return;
  }

  const containerStyle: CSSProperties = {
    background: String(background),
    color: String(text),
    ['--button-background' as string]: buttonBackground,
    ['--button-text-color' as string]: buttonTextColor
  };

  return (
    <section className={classes.section} style={containerStyle}>
      <div className="container">
        <div className={classes.content}>
          {heading && !headingRichText && (
            <h2 className={classes.heading} dangerouslySetInnerHTML={{ __html: heading }} />
          )}
          {headingRichText && (
            <h2 className={classes.heading}>
              <RichText content={headingRichText} />
            </h2>
          )}

          <Button link={link} additionalClasses={classes.link} />
        </div>
      </div>
    </section>
  );
};

'use client';

import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';

import { Media } from '@/components/Media';
import type { Media as MediaType } from '@/payload-types';
import type { VideoProps } from '@/components/Video';
import { PlayIcon } from '@/icons/Play';
import classes from './index.module.scss';
import { clsx } from 'clsx';

const isYoutubePoster = (posterUrl: string): boolean => posterUrl.startsWith('https://img.youtube');

type PlayerProps = Omit<VideoProps, 'poster'> & {
  poster: string | MediaType;
};

export const VideoPlayer = ({
  url,
  autoplay,
  poster,
  width = 1280,
  height = 720,
  posterClassName = ''
}: PlayerProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  const [playing, setPlaying] = useState(false);
  const [isShowPoster, setShowPoster] = useState(Boolean(poster));
  const playerRef = useRef<ReactPlayer | null>(null);

  useEffect(() => {
    if (autoplay && inView) {
      playVideo();
    }
  }, [autoplay, inView]);

  if (!url) {
    return null;
  }

  const playVideo = () => {
    setPlaying(true);
    setShowPoster(false);
  };

  const handleVideoEnd = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, 'seconds');
      setPlaying(false);
    }
  };

  return (
    <div className={classes.videoWrapper} ref={ref} style={{ aspectRatio: Number(width) / Number(height) }}>
      <button className={classes.posterContainer} onClick={playVideo}>
        {typeof poster === 'string' && isYoutubePoster(poster) ? (
          <div className={clsx(classes.poster, posterClassName ?? '')}>
            <Image
              width={Number(width)}
              height={Number(height)}
              src={poster}
              loading="eager"
              fetchPriority="high"
              alt=""
            />
          </div>
        ) : (
          <Media
            className={clsx(classes.poster, posterClassName ?? '')}
            source={poster}
            width={Number(width)}
            height={Number(height)}
          />
        )}

        <div className={classes.playIconWrapper}>
          <PlayIcon width={75} height={75} />
        </div>
      </button>

      {inView && (
        <ReactPlayer
          className={classes.videoContainer}
          playing={playing}
          muted={Boolean(autoplay)}
          playsinline
          url={url}
          width={'100%'}
          height={'100%'}
          onEnded={handleVideoEnd}
          controls
          style={{ display: isShowPoster ? 'none' : 'block' }}
          ref={playerRef}
        />
      )}
    </div>
  );
};

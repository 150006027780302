'use client';

import classes from './index.module.scss';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { RichText } from '@/components/RichText';
import { ExtractBlockField } from '@/utils/types';
import { Chevron } from '@/icons/Chevron';
import { clsx } from 'clsx';
import { ComponentProps, CSSProperties } from 'react';
import { useState, useEffect } from 'react';

type AccordionsProps = {
  accordions: ExtractBlockField<'accordions', 'accordions'>;
  title?: string;
} & ComponentProps<'section'>;

export const Accordions = (props: AccordionsProps) => {
  const {
    title,
    accordions: { accordions, showTitle, appearance, descriptionColor },
    className,
    ...rest
  } = props || {};
  const [hash, setHash] = useState('');

  useEffect(() => {
    setHash(window.location.hash.replace('#', ''));

    const handleHashChange = () => {
      setHash(window.location.hash.replace('#', ''));
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  if (!accordions?.length) {
    return null;
  }

  const showAdditionalText = appearance === 'withAdditionalText';

  return (
    <section
      className={`${classes.section} ${className ?? ''}`}
      style={{ '--description-bg': descriptionColor } as CSSProperties}
      {...rest}
    >
      <div className={clsx('container', classes.container)}>
        {title && showTitle ? <h1>{title}</h1> : null}

        {accordions.map((accordion, index) => {
          if (!accordion.heading || !accordion.description) {
            return null;
          }

          const isOpen = hash === accordion?.anchor;

          return (
            <Collapsible
              key={`${accordion.id}-${hash}-${index}`}
              openOnInit={isOpen}
              transTime={250}
              classPrefix={classes.accordion}
            >
              <CollapsibleToggler className={classes.accordionHeading} id={accordion.anchor ?? undefined}>
                <span className={classes.accordionHeadingText}>{accordion.heading}</span>
                <span className={classes.desktop}>
                  {showAdditionalText && (
                    <>
                      <span className={classes.closed}>Show</span>

                      <span className={classes.opened}>Hide</span>
                    </>
                  )}

                  <Chevron />
                </span>
              </CollapsibleToggler>

              <CollapsibleContent>
                <div className={classes.accordionDescription}>
                  <RichText content={accordion.description} />
                </div>
              </CollapsibleContent>
            </Collapsible>
          );
        })}
      </div>
    </section>
  );
};

'use client';

import { ExtractBlockField } from '@/utils/types';
import { RenderBlocks } from '@/components/RenderBlocks';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { clsx } from 'clsx';

type PosthogExperimentProps = {
  posthogExperimentFields: ExtractBlockField<'posthogExperiment', 'posthogExperimentFields'>;
};

type Layout = NonNullable<PosthogExperimentProps['posthogExperimentFields']['experiments']>[number]['layout'];

export const PosthogExperiment = ({ posthogExperimentFields: { keyword, experiments } }: PosthogExperimentProps) => {
  const experimentValue = useFeatureFlagVariantKey(keyword ?? '');
  const defaultLayout = (experiments?.find(experiment => experiment?.isDefault)?.layout ??
    experiments?.[0]?.layout) as Layout;
  const currentLayout = experiments?.find(experiment => experiment.variantKey === experimentValue)?.layout;

  if (!keyword) {
    return null;
  }

  const layout = currentLayout ?? defaultLayout;

  return (
    <div className={clsx({ hide: !experimentValue })}>
      <RenderBlocks blocks={layout} />
    </div>
  );
};

'use client';

import React, { useEffect, useRef } from 'react';
import classes from './index.module.scss';
import { Media } from '@/components/Media';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Accordions } from '@/components/Accordions';
import { Page } from '@/payload-types';
import SwiperClass from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type IconsAccordionsProps = {
  iconsTabsFields: NonNullable<NonNullable<Page['accordions']>['iconsTabsFields']>;
  currentTab: number;
  setCurrentTab: (tab: number) => void;
};

type AccordionsWrapperProps = {
  item: NonNullable<IconsAccordionsProps['iconsTabsFields']['item']>;
  currentTab: number;
};

const AccordionsWrapper = ({ item, currentTab }: AccordionsWrapperProps) => {
  return (
    <div className={classes.accordionsWrapper}>
      {item.map(({ accordions, accordionsTitle }, index) => {
        if (!accordions) {
          return null;
        }

        const className = index === currentTab ? '' : 'hide';

        return (
          <div className={className}>
            {accordionsTitle && <h2 className={classes.accordionsTitle}>{accordionsTitle}</h2>}

            <Accordions
              key={index}
              accordions={{
                accordions,
                showTitle: false,
                appearance: 'withAdditionalText',
                descriptionColor: '#F7F6F7'
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export const IconsAccordions = ({
  iconsTabsFields: { item, title },
  currentTab,
  setCurrentTab
}: IconsAccordionsProps) => {
  const swiperRef = useRef<SwiperClass | null>(null);

  useEffect(() => {
    const swiper = swiperRef.current;
    if (!swiper) {
      return;
    }

    if (swiper.realIndex !== currentTab) {
      swiper.slideTo(currentTab);
    }
  }, [currentTab]);

  const onSlideChange = ({ realIndex }: SwiperClass) => {
    if (currentTab !== realIndex) {
      setCurrentTab(realIndex);
    }
  };

  if (!item) {
    return null;
  }

  return (
    <section className={classes.section}>
      <div className={`${classes.iconsWrapper} ${classes.desktop}`}>
        <div className={`container ${classes.container}`}>
          <div className={classes.row}>
            {item.map(({ image, title }, index) => (
              <div className={classes.col} key={index}>
                <button
                  className={`${classes.item} ${currentTab === index ? classes.active : ''}`}
                  onClick={() => setCurrentTab(index)}
                >
                  {image && (
                    <div className={classes.imageWrapper}>
                      <Media source={image} width={75} height={75} />
                    </div>
                  )}

                  {title && <h3 className={classes.itemTitle}>{title}</h3>}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.mobile}>
        {title && <h2 className={classes.itemsTitle}>{title}</h2>}

        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          spaceBetween={16}
          grabCursor={true}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          onSwiper={swiper => (swiperRef.current = swiper)}
          onSlideChange={onSlideChange}
        >
          {item.map(({ image, title }, index) => (
            <SwiperSlide key={index}>
              <div className={classes.mobileItem}>
                {image && (
                  <div className={classes.imageWrapper}>
                    <Media source={image} width={75} height={75} />
                  </div>
                )}

                {title && <h3 className={classes.itemTitle}>{title}</h3>}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <AccordionsWrapper item={item} currentTab={currentTab} />
    </section>
  );
};

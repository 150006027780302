import type { CSSProperties } from 'react';

import { RichText as RichTextComponent } from '@/components/RichText';
import type { ExtractBlockField } from '@/utils/types';
import classes from './index.module.scss';
import parseColor from 'parse-color';
import { clsx } from 'clsx';

type RichTextProps = {
  RichText: ExtractBlockField<'RichText', 'RichText'>;
};

export const RichText = ({
  RichText: { content, textSize, description, descriptionRichText, backgroundColor }
}: RichTextProps) => {
  if (!content && !description) {
    return null;
  }

  let textColor = '#434343';

  if (backgroundColor) {
    const parsedBackgroundColor = parseColor(backgroundColor);

    if (parsedBackgroundColor.rgb.reduce((acc, canal) => (acc += canal), 0) > 300) {
      textColor = 'white';
    }
  }

  return (
    <section
      className={clsx(classes.section, { [classes.sectionWithBg]: backgroundColor }, classes[textSize || 'small'])}
      style={{ '--bg-color': backgroundColor || '', '--text-color': textColor } as CSSProperties}
    >
      <div className={clsx(classes.container, 'container')}>
        <div className={classes.wrapper}>
          {content && (
            <h2 className={classes.heading}>
              <RichTextComponent content={content} />
            </h2>
          )}

          {description && !descriptionRichText && (
            <p className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {descriptionRichText && <RichTextComponent content={descriptionRichText} className={classes.description} />}
        </div>
      </div>
    </section>
  );
};
